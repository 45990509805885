import React from 'react';
import PropTypes from 'prop-types';

import { graphql } from 'gatsby';

import StaticPage from '../components/staticpage/StaticPage';
import SEO from '../components/SEO';
import Thing from '../components/stuff/Thing';

const StuffPage = ({ location, data }) => {
    const pagepathname = location.pathname;
    const { lead, intro } = data.things.nodes[0];

    return (
        <StaticPage title="Kitchen stuff you might need">
            <SEO
                title="Kitchen stuff you might need"
                pagepathname={pagepathname}
            />

            <p className="content__lead">
                {lead}
            </p>

            <p className="content__intro">
                {intro}
            </p>

            <div className="stuff">
                {data.things.nodes[0].things.map((thing, index) => (
                    <Thing
                        key={`thing-${index}`}
                        name={thing.name}
                        description={thing._rawDescription}
                        index={index}
                    />
                ))}
            </div>
        </StaticPage>
    );
};

export default StuffPage;

export const query = graphql`
    query StuffQuery {
        things: allSanityStuff {
            nodes {
                intro
                lead
                things {
                    _rawDescription(resolveReferences: {maxDepth: 10})
                    name
                    _type
                    _key
                }
            }
        }
    }
`;

StuffPage.propTypes = {
    data: PropTypes.object,
    location: PropTypes.object,
};
