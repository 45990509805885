import React from 'react';
import PropTypes from 'prop-types';

import BlockContent from '@sanity/block-content-to-react';

import serializers from '../../utils/serializers';

import './stuff.css';

const Thing = ({ name, index, description }) => {
    return (
        <div className="stuff__item" key={`${name}-${index}`}>
            <h2 className="u-tertiary-title stuff__title">
                {name}
            </h2>

            <div className="stuff__desc">
                <BlockContent blocks={description} serializers={serializers} />
            </div>
        </div>
    );
};

export default Thing;

Thing.propTypes = {
    name: PropTypes.string,
    index: PropTypes.number,
    description: PropTypes.object,
};
